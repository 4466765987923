import React, { createContext, useContext, useState, useEffect } from "react";

// Create the Context
const BingoContext = createContext();

// Create a provider component
export const BingoProvider = ({ children }) => {
  // Initialize state with data from localStorage or default to "FREE" cell selected
  const [selectedCells, setSelectedCells] = useState(() => {
    const saved = localStorage.getItem("selectedCells");
    return saved ? JSON.parse(saved) : [12];
  });

  // Save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedCells", JSON.stringify(selectedCells));
  }, [selectedCells]);

  return (
    <BingoContext.Provider value={{ selectedCells, setSelectedCells }}>
      {children}
    </BingoContext.Provider>
  );
};

// Custom hook for consuming the context
export const useBingo = () => useContext(BingoContext);
