import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { useBingo } from "./BingoContext";
import "./Bingo.css";

const Bingo = () => {
  const { selectedCells, setSelectedCells } = useBingo();

  const initialBingoTexts = [
    "Damn",
    "you",
    "look",
    "so",
    "fucking",
    "hot",
    "today",
    "you",
    "scrumptious",
    "little",
    "thing.",
    "You",
    "FREE",
    "tonight?",
    "Because",
    "I'm",
    "really",
    "broke.",
    "Anyway",
    "hope",
    "you",
    "have",
    "a nice",
    "day",
    "cutie xx",
  ];

  const [bingoTexts, setBingoTexts] = useState(
    JSON.parse(localStorage.getItem("bingoTexts")) || initialBingoTexts
  );
  const [winningCells, setWinningCells] = useState(
    JSON.parse(localStorage.getItem("winningCells")) || []
  );
  const [isWinner, setIsWinner] = useState(
    JSON.parse(localStorage.getItem("isWinner")) || false
  );

  const winningPatterns = [
    [0, 1, 2, 3, 4],
    [5, 6, 7, 8, 9],
    [10, 11, 12, 13, 14],
    [15, 16, 17, 18, 19],
    [20, 21, 22, 23, 24],
    [0, 5, 10, 15, 20],
    [1, 6, 11, 16, 21],
    [2, 7, 12, 17, 22],
    [3, 8, 13, 18, 23],
    [4, 9, 14, 19, 24],
    [0, 6, 12, 18, 24],
    [4, 8, 12, 16, 20],
  ];

  const checkForWin = (selected) => {
    const newWinningCells = [...winningCells];

    winningPatterns.forEach((pattern) => {
      if (pattern.every((cell) => selected.includes(cell))) {
        pattern.forEach((cell) => {
          if (!newWinningCells.includes(cell)) {
            newWinningCells.push(cell);
          }
        });
      }
    });

    if (newWinningCells.length > 0) {
      setWinningCells(newWinningCells);
      setIsWinner(true);
      localStorage.setItem("winningCells", JSON.stringify(newWinningCells));
      localStorage.setItem("isWinner", JSON.stringify(true));
    }
  };

  const toggleCell = (index) => {
    if (index === 12) return;

    setSelectedCells((prev) => {
      const updatedCells = prev.includes(index)
        ? prev.filter((cell) => cell !== index)
        : [...prev, index];

      checkForWin(updatedCells);
      return updatedCells;
    });
  };

  const resetBingo = () => {
    setSelectedCells([12]);
    setWinningCells([]);
    setIsWinner(false);
    setBingoTexts(initialBingoTexts);
    localStorage.removeItem("winningCells");
    localStorage.removeItem("isWinner");
    localStorage.setItem("bingoTexts", JSON.stringify(initialBingoTexts));
    localStorage.setItem("selectedCells", JSON.stringify([12]));
  };

  const shuffleBingo = () => {
    const shuffledTexts = [...initialBingoTexts]
      .filter((_, i) => i !== 12)
      .sort(() => Math.random() - 0.5);

    shuffledTexts.splice(12, 0, "FREE");
    setBingoTexts(shuffledTexts);
    setSelectedCells([12]);
    setWinningCells([]);
    setIsWinner(false);
    localStorage.setItem("bingoTexts", JSON.stringify(shuffledTexts));
    localStorage.setItem("selectedCells", JSON.stringify([12]));
    localStorage.removeItem("winningCells");
    localStorage.removeItem("isWinner");
  };

  useEffect(() => {
    localStorage.setItem("selectedCells", JSON.stringify(selectedCells));
  }, [selectedCells]);

  return (
    <div className="bingo-container">
      <Link to="/" className="home-icon">
        <FaHome size={30} />
      </Link>

      <h1>Phobia Bingo</h1>
      <p className={isWinner ? "win-text" : "no-cheating"}>
        {isWinner ? "WIN!" : "No cheating!"}
      </p>
      <div className="bingo-grid">
        {bingoTexts.map((text, i) => (
          <div
            key={i}
            className={`bingo-cell ${
              winningCells.includes(i)
                ? "winning-cell"
                : selectedCells.includes(i)
                ? "selected"
                : ""
            }`}
            onClick={() => toggleCell(i)}
          >
            {text}
          </div>
        ))}
      </div>

      <div className="bingo-buttons">
        <button onClick={resetBingo} className="reset-button">
          Reset
        </button>
        <button onClick={shuffleBingo} className="shuffle-button">
          Shuffle
        </button>
      </div>

      {/* Footer */}
      <footer className="footer">&copy; 2024 Neri. All rights reserved.</footer>
    </div>
  );
};

export default Bingo;
