import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Bingo from "./Bingo";

function App() {
  const [message, setMessage] = useState("Is he?");
  const [noClickCount, setNoClickCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const handleAnswer = (answer) => {
    if (answer === "yes") {
      setMessage("Correct!");
    } else {
      setMessage("Try again!");
      setNoClickCount((prev) => prev + 1);
      if (noClickCount + 1 === 10) {
        setShowPopup(true);
      }
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setNoClickCount(0); // Reset the count after popup is shown
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="app-container">
              {/* Bingo Button */}
              <Link to="/bingo" className="bingo-button">
                Bingo
              </Link>

              <h1>{message}</h1>
              <div>
                <button
                  onClick={() => handleAnswer("no")}
                  className="no-button"
                >
                  No
                </button>
                <button
                  onClick={() => handleAnswer("yes")}
                  className="yes-button"
                >
                  Yes
                </button>
              </div>

              {/* Dim Background */}
              {showPopup && <div className="dim-background"></div>}

              {/* Popup */}
              {showPopup && (
                <div className="popup-overlay">
                  <div className="popup">
                    <div className="popup-header">
                      <button className="popup-close" onClick={closePopup}>
                        &times;
                      </button>
                    </div>
                    <h2>Get III-d!</h2>
                    <img
                      src="iii.jpg"
                      alt="Get III-d!"
                      className="popup-image"
                    />
                  </div>
                </div>
              )}

              {/* Footer */}
              <footer className="footer">
                &copy; 2024 Neri. All rights reserved.
              </footer>
            </div>
          }
        />
        <Route path="/bingo" element={<Bingo />} />
      </Routes>
    </Router>
  );
}

export default App;
